import React from "react";

import { Box, Divider, FormControl, FormLabel, Grid, GridItem } from "@chakra-ui/react";

import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import InputField from "common/components/InputField/InputField";
import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion07: React.FC<ISeccionProps> = (props) => {
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_7);

	const seccion2 = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_2);
	const seccion3 = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_3);
	const seccion5 = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_5);
	const seccion6 = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_6);

	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_7_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const tipoDeEntidad = evaluacion?.seccion_1?.tipo_de_actividad;

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(8);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_7");

	const desayunos = (
		(Number(seccion3?.unidades_desayuno) *
			(Number(seccion3?.porcentaje_desayunos) / 100) *
			Number(seccion3?.dias_semana_desayunos)) /
		7
	).toFixed(2);

	const meriendas = (
		(Number(seccion3?.unidades_merienda) *
			(Number(seccion3?.porcentaje_meriendas) / 100) *
			Number(seccion3?.dias_semana_meriendas)) /
		7
	).toFixed(2);

	const comidas = (
		(Number(seccion3?.unidades_comida) *
			(Number(seccion3?.porcentaje_comidas) / 100) *
			Number(seccion3?.dias_semana_comidas)) /
		7
	).toFixed(2);

	const cenas = (
		(Number(seccion3?.unidades_cena) *
			(Number(seccion3?.porcentaje_cenas) / 100) *
			Number(seccion3?.dias_semana_cenas)) /
		7
	).toFixed(2);

	const totalPersonasEquivalentes = (Number(desayunos) + Number(comidas) + Number(meriendas) + Number(cenas)).toFixed(
		2,
	);

	const kgUsuarioMes = React.useMemo(() => {
		if (!seccion2 || !seccion5 || !seccion6) return 0;

		const totalKgRecibidoBam = Number(seccion5?.total_kg_recibido_bam || 0) || 0;
		const totalAnoOtrasAportaciones = Number(seccion6?.total_ano_otras_aportaciones || 0);
		const beneficiariosTotales =
			tipoDeEntidad === "Reparto"
				? Number(seccion2?.beneficiarios_totales || 0)
				: Number(totalPersonasEquivalentes || 0);
		const mesesActividadAno = Number(seccion2?.meses_actividad_ano || 0);

		if (beneficiariosTotales === 0 || mesesActividadAno === 0) return 0;

		return (totalKgRecibidoBam + totalAnoOtrasAportaciones) / (beneficiariosTotales * mesesActividadAno);
	}, [seccion2, seccion5, seccion6, tipoDeEntidad, totalPersonasEquivalentes]);

	React.useEffect(() => {
		if (
			evaluacion?.estado_evaluacion === "En proceso" &&
			kgUsuarioMes !== seccionDataActual?.kg_usuario_mes &&
			!isNaN(kgUsuarioMes)
		) {
			sendInputValue("kg_usuario_mes", kgUsuarioMes);
		}
	}, [kgUsuarioMes]);

	return (
		<Box>
			<SeccionHeader seccion="07" />
			<Box width="100%">
				<Divider marginBottom="1.5rem" color="gray.200" />
				<Grid templateColumns="repeat(4, 1fr)" gap="2rem" alignItems="end">
					<GridItem>
						<FormControl>
							<FormLabel>kg del BAM</FormLabel>
							<InputField isDisabled value={formatNumber(seccion5?.total_kg_recibido_bam)} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>kg de otros canales</FormLabel>
							<InputField isDisabled value={formatNumber(seccion6?.total_ano_otras_aportaciones)} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>kg totales recibidos</FormLabel>
							<InputField
								isDisabled
								value={formatNumber(
									Number(seccion5?.total_kg_recibido_bam || 0) + Number(seccion6?.total_ano_otras_aportaciones || 0),
								)}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Nº usuarios totales</FormLabel>
							<InputField isDisabled value={formatNumber(seccion2?.beneficiarios_totales)} />
						</FormControl>
					</GridItem>
					<GridItem colSpan={3} />
					<GridItem>
						<FormControl>
							<FormLabel>kg / Usuario / Mes</FormLabel>
							<InputField isDisabled value={formatNumber(kgUsuarioMes)} />
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid
					templateColumns="repeat(2, 1fr)"
					columnGap="2.25rem"
					rowGap="1.75rem"
					style={{ pageBreakInside: "avoid" }}
				>
					<GridItem>
						{seccionDataActual?.datos_correctos_7 !== undefined && (
							<FormControl>
								<FormLabel>¿Los datos son correctos?</FormLabel>
								<Box width="fit-content">
									<CheckboxGroup
										disableNA
										name="datos_correctos_7"
										defaultValue={seccionDataActual?.datos_correctos_7}
										onChange={(name, value) => sendInputValue(name, value)}
										changed={changeDetector("datos_correctos_7")}
										isDisabled={isDisabled}
									/>
								</Box>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={3} mb=".5rem">
						<FormControl>
							<FormLabel>Comentarios</FormLabel>
							<TextAreaInput
								name="comentarios_7"
								defaultValue={seccionDataActual?.comentarios_7}
								{...changeDetector("comentarios_7")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
			</Box>

			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_7_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_7_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion07;
