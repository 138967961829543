export const titulosSecciones = {
	seccion00: "Resumen visita anterior",
	seccion01: "Información entidad benéfica",
	seccion02: "Control de entregas e informes de derivación",
	seccion03: "Personas beneficiarias",
	seccion04: "Turnos de recogida",
	seccion05: "Alimentos que la EB ha recibido del BAM",
	seccion06: "Alimentos que la EB ha recibido por otros canales",
	seccion07: "Kg totales que entrega la entidad benéfica a los beneficiarios",
	seccion08: "Logística y procedimientos administrativos",
	seccion09: "Información económica de la EB",
	seccion10: "Valoración de la EB por el visitador",
};

export const subtitulosSecciones = {
	seccion00: "",
	seccion01: "",
	seccion02:
		"Indica cómo se reparten los alimentos a las personas beneficiarias, así como las personas derivadas de otras organizaciones.",
	seccion03: "Indica en número de usuarios por edades, y el número y distribución de ingestas que se reparten.",
	seccion04: "Este apartado es informativo sobre los días y almacenes de recogida de alimentos.",
	seccion05: "Resumen de los alimentos ha recibido la EB en los últimos 12 meses.",
	seccion06:
		"Indica los kilos de comida que la EB ha recibido de otros canales diferentes del Banco de Alimentos. Escribe la cantidad en kilos. Si no dispones de esta información escríbelo en euros y se calcularán kg automáticamente. El valor de conversión es:",
	seccion07: "Total de kilogramos de comida que la Entidad Benéfica ha recibido en 12 meses.",
	seccion08: "Cómo se almacenan y transportan los alimentos en la entidad benéfica.",
	seccion09: "Datos económicos relativos a los gastos e ingresos de la entidad benéfica.",
	seccion10:
		"Las observaciones pueden recoger: el perfil de beneficiarios, otras actividades de la EB, sistema de distribución, quién firma la valoración, etc.",
};
