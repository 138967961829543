import React from "react";

import {
	Box,
	Divider,
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { checkPermission } from "helpers/checkPermissions";
import { useAppSelector } from "store/store";

import { BotonAdelantarVisita } from "../BotonAdelantarVisita/BotonAdelantarVisita";
import { BotonCambiarEstado } from "../BotonCambiarEstado/BotonCambiarEstado";
import { BotonRechazarEvaluacion } from "../BotonRechazarEvaluacion/BotonRechazarEvaluacion";
import { HojaDeVisita } from "../HojaDeVisita";
import { ModalAdelantarVisita } from "../Modales/AdelantarVisita/ModalAdelantarVisita";
import { ModalDeEstados } from "../Modales/ModalDeEstados/ModalDeEstados";
import { modalSelector } from "../Modales/ModalDeEstados/modalSelector";
import { ModalRechazada } from "../Modales/Rechazar/ModalRechazar";
import { Resolucion } from "../Resolucion";
import {
	Seccion00,
	Seccion01,
	Seccion02,
	Seccion03,
	Seccion04,
	Seccion05,
	Seccion06,
	Seccion07,
	Seccion08,
	Seccion09,
	Seccion10,
} from "../secciones";
import { SelectorEvaluacion } from "../SelectorEvaluacion/SelectorEvaluacion";

export const Evaluacion: React.FC = () => {
	const { codEB, numeroEvaluacion } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const resolucion = searchParams.get("resolucion");
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);
	const usuarioSoloLectura = permisosUsuario[0]?.cargo_funcion === "Solo lectura";

	const isVisitador = checkPermission(permisosUsuario, "visitador");

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenAdelantar, onOpen: onOpenAdelantar, onClose: onCloseAdelantar } = useDisclosure();
	const { isOpen: isOpenRechazar, onOpen: onOpenRechazar, onClose: onCloseRechazar } = useDisclosure();

	const [seccionActiva, setSeccionActiva] = React.useState<number>(0);

	const [formRef, setFormRef] = React.useState<HTMLFormElement | null>(null);

	const { reset } = useForm();

	React.useEffect(() => {
		if (formRef) {
			const campos = {};

			Object.entries(evaluacionSeleccionada || {}).forEach(([key, value]) => {
				if (key.startsWith("seccion_")) {
					Object.entries(value).forEach(([key2, value2]) => {
						campos[key2] = value2;
					});
				} else {
					campos[key] = value;
				}
			});

			formRef.reset();
			reset(campos);
		}
	}, [evaluacionSeleccionada, formRef]);

	const SECCIONES = {
		"00": Seccion00,
		"01": Seccion01,
		"02": Seccion02,
		"03": Seccion03,
		"04": Seccion04,
		"05": Seccion05,
		"06": Seccion06,
		"07": Seccion07,
		"08": Seccion08,
		"09": Seccion09,
		"10": Seccion10,
	};

	const modalInfoSelector = (estadoActual: string) => {
		const modalInfo = modalSelector.find((modal) => modal.estadoActual === estadoActual);
		return modalInfo;
	};

	const seccionesAvailable = Object.keys(evaluacionSeleccionada || {}).filter((key) => key.startsWith("seccion_"));
	const allSectionsCompleted =
		(evaluacionSeleccionada &&
			seccionesAvailable.every((seccion) => evaluacionSeleccionada[seccion][`${seccion}_completada`])) ||
		false;

	const isSectionCompleted = (seccion: string) => {
		const formattedKey = parseInt(seccion, 10);
		return (
			evaluacionSeleccionada && evaluacionSeleccionada[`seccion_${formattedKey}`][`seccion_${formattedKey}_completada`]
		);
	};

	const ref = React.useRef<HTMLDivElement>(null);

	const autoScrollToTop = React.useCallback(() => {
		if (ref.current) {
			ref.current.scrollTop = 0;
		}
	}, [ref]);

	React.useEffect(() => {
		autoScrollToTop();
	}, [seccionActiva]);

	return (
		<Box padding="1.25rem 2rem" backgroundColor="white" borderRadius="0.375rem">
			<Flex
				direction="row"
				w="100%"
				justifyContent="space-between"
				alignItems="center"
				m=".75rem 0"
				alignContent="center"
			>
				<Box display={{ sm: "block", md: "none" }} w="30%">
					<SelectorEvaluacion />
				</Box>
				<Text color="gray.600" textStyle="body3" marginBottom="0.5rem" display={{ sm: "none", md: "block" }}>
					Evaluación{" "}
					{evaluacionSeleccionada &&
						new Date(evaluacionSeleccionada?.fecha_visita_programada).toLocaleString("es-ES", {
							month: "long",
							year: "numeric",
						})}
				</Text>
				{!usuarioSoloLectura && (
					<Flex direction="row" alignItems="center">
						{["Programada", "Adelantada"].includes(evaluacionSeleccionada?.estado_evaluacion as string) && (
							<BotonAdelantarVisita onOpenAdelantar={onOpenAdelantar} />
						)}
						{(evaluacionSeleccionada?.estado_evaluacion === "Completada" ||
							evaluacionSeleccionada?.estado_evaluacion === "Supervisada") &&
							!isVisitador && <BotonRechazarEvaluacion onOpenRechazar={onOpenRechazar} />}
						{evaluacionSeleccionada?.estado_evaluacion && evaluacionSeleccionada?.estado_evaluacion !== "Validada" && (
							<BotonCambiarEstado isDisabled={!allSectionsCompleted} onOpen={() => onOpen()} />
						)}
					</Flex>
				)}
			</Flex>
			<Tabs isLazy variant="unstyled" position="relative">
				<TabList borderBottom={"1.25px solid"} borderColor="gray.200">
					<Tab onClick={() => navigate(`/${codEB}/${numeroEvaluacion}`)}>
						<Text textStyle="body2" color="black" fontWeight="semibold">
							Hoja de visita
						</Text>
					</Tab>
					{evaluacionSeleccionada?.estado_evaluacion !== "Programada" &&
						evaluacionSeleccionada?.estado_evaluacion !== "En proceso" &&
						evaluacionSeleccionada?.estado_evaluacion !== "Adelantada" && (
							<Tab onClick={() => navigate({ search: `?${createSearchParams({ resolucion: "true" })}` })}>
								<Text textStyle="body2" color="black" fontWeight="semibold">
									Resolución
								</Text>
							</Tab>
						)}
				</TabList>
				<TabIndicator mt="-3px" height="2px" bg="black" />
				<TabPanels>
					<TabPanel>{evaluacionSeleccionada && <HojaDeVisita evaluacion={evaluacionSeleccionada} />}</TabPanel>
				</TabPanels>
			</Tabs>
			{!resolucion && (
				<Tabs
					isLazy
					variant="unstyled"
					index={seccionActiva}
					onChange={setSeccionActiva}
					position="relative"
					as="form"
					ref={(r) => setFormRef(r as unknown as HTMLFormElement)}
				>
					<TabList borderBottom={"1.25px solid"} borderColor="gray.200">
						{Object.entries(SECCIONES)
							.sort()
							.map(([key]) => (
								<Tab
									key={key}
									display={
										key === "03" && evaluacionSeleccionada?.seccion_1?.tipo_de_actividad === "Reparto"
											? "none"
											: "block"
									}
								>
									<Text textStyle="body1" color={isSectionCompleted(key) ? "blue.500" : "gray.800"}>
										{key}
									</Text>
								</Tab>
							))}
					</TabList>
					<TabIndicator
						mt="-3px"
						height="2px"
						bg={isSectionCompleted(seccionActiva.toString()) ? "blue.500" : "gray.800"}
					/>
					<Box
						maxH={{ sm: "50vh", md: "55vh" }}
						overflowY="auto"
						ref={ref}
						sx={{
							"::-webkit-scrollbar": {
								width: ".75rem",
							},
						}}
					>
						<TabPanels>
							{Object.entries(SECCIONES)
								.sort()
								.map(([key, Component]) => (
									<TabPanel key={key} paddingBottom={{ sm: "7rem", md: "0" }}>
										<Component setSeccionActiva={setSeccionActiva} abrirModalCambioEstado={onOpen} />
									</TabPanel>
								))}
						</TabPanels>
					</Box>
				</Tabs>
			)}
			{resolucion && (
				<React.Fragment>
					<Divider mb="1.5rem" />
					<Resolucion openModal={onOpen} />
				</React.Fragment>
			)}
			{codEB && evaluacionSeleccionada && !!modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion) && (
				<ModalDeEstados
					isOpen={isOpen}
					onClose={onClose}
					codEB={codEB}
					estado={modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion)?.estado || "Completada"}
					texto1={modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion)?.texto1 || ""}
					informar_a={modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion)?.informar_a || ""}
					color={modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion)?.color || ""}
					hoverBoton={modalInfoSelector(evaluacionSeleccionada?.estado_evaluacion)?.hoverBoton || ""}
				/>
			)}
			{codEB && numeroEvaluacion && <ModalRechazada codEB={codEB} isOpen={isOpenRechazar} onClose={onCloseRechazar} />}
			<ModalAdelantarVisita codEB={codEB} isOpen={isOpenAdelantar} onClose={onCloseAdelantar} />
		</Box>
	);
};
