import React from "react";

import {
	Box,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Select,
	Text,
	VStack,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";

import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import InputField from "common/components/InputField/InputField";
import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

enum HORARIO {
	Mañana = "Mañana",
	Tarde = "Tarde",
	Ambas = "Ambas",
}
const HORARIO_OPCIONES = ["Mañana", "Tarde", "Ambas"];
const DIAS_SEMANA = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
const CODIGO_DIAS_SEMANA = ["L", "M", "X", "J", "V", "S", "D"];
const TURNOS = ["1 semana", "2 semana", "3 semana", "4 semana", "5 semana"];
const CODIGO_TURNOS = ["1", "2", "3", "4", "5"];

const getCodigoDiasSemana = (value?: string[]) => {
	if (!value) return undefined;
	return CODIGO_DIAS_SEMANA.map((it, idx) => (value.includes(DIAS_SEMANA[idx]) ? it : "0")).join("-");
};
const getCodigoTurnos = (value?: string[]) => {
	if (!value) return undefined;
	return CODIGO_TURNOS.map((it, idx) => (value.includes(TURNOS[idx]) ? it : "0")).join("-");
};
const getListaDiasSemana = (value?: string) => {
	if (!value) return undefined;
	const result: string[] = [];
	const dias = value?.split("-");
	let index = 0;
	for (const dia of dias) {
		if (dia === CODIGO_DIAS_SEMANA[index]) result.push(DIAS_SEMANA[index]);
		index += 1;
	}
	return result;
};
const getListaTurnos = (value?: string) => {
	if (!value) return undefined;
	const result: string[] = [];
	const dias = value?.split("-");
	let index = 0;
	for (const dia of dias) {
		if (dia === CODIGO_TURNOS[index]) result.push(TURNOS[index]);
		index += 1;
	}
	return result;
};
const Seccion02: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_2);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_2_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_2");

	const repartoCondition = evaluacion?.seccion_1?.tipo_de_actividad === "Reparto" ? 4 : 3;

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(repartoCondition);

	const debouncedChangeHandler = React.useMemo(
		() =>
			debounce(
				(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
					sendInputValue(event.target.name, event.target.value),
				500,
			),
		[seccionDataActual],
	);

	const debouncedMultiChangeHandler = React.useMemo(
		() => debounce((name: string, value: string) => sendInputValue(name, value), 500),
		[seccionDataActual],
	);

	const [diasSeco, setDiasSeco] = React.useState<string[] | undefined>(
		getListaDiasSemana(evaluacion?.seccion_2.reparto_seco_dias),
	);

	const [horarioSeco, setHorarioSeco] = React.useState<HORARIO | undefined>(
		evaluacion?.seccion_2.reparto_seco_horario as HORARIO | undefined,
	);

	const [turnosSeco, setTurnosSeco] = React.useState<string[] | undefined>(
		getListaTurnos(evaluacion?.seccion_2.reparto_seco_turnos),
	);

	const [diasFyH, setDiasFyH] = React.useState<string[] | undefined>(
		getListaDiasSemana(evaluacion?.seccion_2.reparto_fyh_dias),
	);

	const [horarioFyH, setHorarioFyH] = React.useState<HORARIO | undefined>(
		evaluacion?.seccion_2.reparto_fyh_horario as HORARIO | undefined,
	);

	const [turnosFyH, setTurnosFyH] = React.useState<string[] | undefined>(
		getListaTurnos(evaluacion?.seccion_2.reparto_fyh_turnos),
	);

	const checkboxesList = [
		{
			label:
				"¿Los beneficiarios son recibidos en lugar digno respetando la confidencialidad cuando facilitan sus datos personales?",
			name: "lugar_de_entrega_digno_confidencialidad_datos_beneficiarios",
		},
		{
			label:
				"¿Tienen certificado de TS o equivalente que avale la necesidad de ayuda alimentaria de los beneficiarios?",
			name: "tienen_certificados_de_necesidad",
		},
		{
			label: "¿La EB cumple con el requisito de no discriminación?",
			name: "cumplen_no_discriminacion",
		},
		{
			label: "Los beneficiarios, ¿aportan algún dinero? (no por alimentos)",
			name: "beneficiarios_aportan_dinero",
		},
		{
			label: "¿La EB asegura que los alimentos llegan en buen estado a los beneficiarios?",
			name: "eb_asegura_buen_estado_alimentos",
		},
		{
			label: "Para recoger los alimentos, ¿se cita a los beneficiarios en algún orden?",
			name: "orden_de_reparto",
		},
		{
			label: "¿El reparto es proporcional al número de miembros familiares?",
			name: "reparto_proporcional_a_familiares",
		},
		{
			label: "¿Los beneficiarios reciben los alimentos gratuitamente?",
			name: "reparto_gratuito",
		},
		{
			label: "¿Los beneficiarios firman las cantidades de alimentos de cada entrega?",
			name: "beneficiarios_firman",
		},
	];

	const totalBeneficiarios = React.useMemo(() => {
		return (
			(Number(seccionDataActual?.beneficiarios_0_18_meses) || 0) +
			(Number(seccionDataActual?.beneficiarios_mas_de_18_meses) || 0)
		);
	}, [seccionDataActual]);

	React.useEffect(() => {
		if (totalBeneficiarios !== seccionDataActual?.beneficiarios_totales) {
			sendInputValue("beneficiarios_totales", totalBeneficiarios.toString());
		}
	}, [totalBeneficiarios, seccionDataActual]);

	return (
		<Box>
			<SeccionHeader seccion="02">
				<HStack justifyContent="flex-end">
					<VStack alignItems="flex-start" spacing="0">
						<Text textStyle="heading4" color="gray.600" fontWeight="600">
							{evaluacion?.seccion_2.beneficiarios_totales}
						</Text>
						<Text as="span" textStyle="body2" color="gray.600">
							Beneficiarios totales
						</Text>
					</VStack>
				</HStack>
			</SeccionHeader>
			<Box width="100%">
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Datos tribal
						</Text>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº beneficiarios (0-18 meses)</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.tribal_beneficiarios_0_18_meses}</Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº beneficiarios (mayores 18 meses)</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.tribal_beneficiarios_mas_de_18_meses ?? "-"}</Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Beneficiarios totales</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.tribal_beneficiarios_totales ?? "-"}</Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº familias</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.tribal_n_familias ?? "-"} </Text>
							</VStack>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Datos GUF{" "}
							{evaluacion?.seccion_2?.guf_fecha
								? "a " + DateTime.fromJSDate(new Date(evaluacion.seccion_2.guf_fecha)).toFormat("dd/MM/yyyy")
								: ""}
						</Text>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Lactantes</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.guf_lactantes ?? "-"} </Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Adultos</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.guf_adultos ?? "-"}</Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Beneficiarios</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.guf_beneficiarios ?? "-"} </Text>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Ufs</FormLabel>
								<Text paddingLeft="8.25px">{evaluacion?.seccion_2.guf_ufs ?? "-"} </Text>
							</VStack>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Datos visita
						</Text>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº de beneficiarios (0-18 meses)</FormLabel>
								<InputField
									name="beneficiarios_0_18_meses"
									defaultValue={seccionDataActual?.beneficiarios_0_18_meses}
									{...changeDetector("beneficiarios_0_18_meses")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº de beneficiarios (Mayores de 18 meses)</FormLabel>
								<InputField
									name="beneficiarios_mas_de_18_meses"
									defaultValue={seccionDataActual?.beneficiarios_mas_de_18_meses}
									{...changeDetector("beneficiarios_mas_de_18_meses")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Beneficiarios totales</FormLabel>
								<InputField isDisabled value={totalBeneficiarios} />
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº familias</FormLabel>
								<InputField
									name="n_familias"
									defaultValue={seccionDataActual?.n_familias}
									{...changeDetector("n_familias")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº meses actividad/año</FormLabel>
								<InputField
									name="meses_actividad_ano"
									defaultValue={seccionDataActual?.meses_actividad_ano}
									{...changeDetector("meses_actividad_ano")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>% Dif con GUF</FormLabel>
								<InputField
									isDisabled
									value={
										evaluacion?.seccion_2.guf_beneficiarios
											? (
													(100 * (totalBeneficiarios - evaluacion?.seccion_2.guf_beneficiarios)) /
													evaluacion?.seccion_2.guf_beneficiarios
											  ).toFixed(2)
											: ""
									}
								/>
							</VStack>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" alignItems="end" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Informes de derivación: número de beneficiarios derivados
						</Text>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>De cáritas</FormLabel>
							<InputField
								name="derivados_caritas"
								defaultValue={seccionDataActual?.derivados_caritas}
								{...changeDetector("derivados_caritas")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>De Servicios Sociales</FormLabel>
							<InputField
								name="derivados_servicios_sociales"
								defaultValue={seccionDataActual?.derivados_servicios_sociales}
								{...changeDetector("derivados_servicios_sociales")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>De la propia entidad</FormLabel>
							<InputField
								name="derivados_propia_entidad"
								defaultValue={seccionDataActual?.derivados_propia_entidad}
								{...changeDetector("derivados_propia_entidad")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Sin informe</FormLabel>
							<InputField
								name="sin_inf_derivados"
								defaultValue={seccionDataActual?.sin_inf_derivados}
								{...changeDetector("sin_inf_derivados")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" alignItems="end" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Días de reparto: seco
						</Text>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Dias semana</FormLabel>
							<Menu closeOnSelect={false} isLazy matchWidth strategy="fixed">
								<MenuButton
									w="100%"
									type="button"
									disabled={isDisabled}
									style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
								>
									<Select
										placeholder={getCodigoDiasSemana(diasSeco)}
										isReadOnly
										isDisabled={isDisabled}
										value={getCodigoDiasSemana(diasSeco)}
										name="reparto_seco_dias"
										{...changeDetector("reparto_seco_dias")}
									/>
								</MenuButton>
								<MenuList maxH="15rem" overflow="auto">
									<MenuOptionGroup
										type="checkbox"
										value={diasSeco}
										onChange={(e) => {
											let value = Array.isArray(e) ? e : [e];
											if (e.includes("all")) {
												if (value.length === DIAS_SEMANA.length + 1) value = [];
												else value = DIAS_SEMANA;
											}
											const code = getCodigoDiasSemana(value);
											if (code) {
												setDiasSeco(value);
												debouncedMultiChangeHandler("reparto_seco_dias", code);
											}
										}}
									>
										<MenuItemOption key={"option-" + "all"} value="all">
											{diasSeco?.length === DIAS_SEMANA.length ? "Deseleccionar todo" : "Seleccionar todo"}
										</MenuItemOption>
										<MenuDivider />
										{DIAS_SEMANA.map((it, idx) => (
											<MenuItemOption key={"option-" + idx} value={it}>
												{it}
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Menu>
						</FormControl>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Horario</FormLabel>
							<Select
								value={horarioSeco}
								name="reparto_seco_horario"
								onChange={(event) => {
									const newValue = event.target.value as HORARIO;
									setHorarioSeco(newValue);
									debouncedChangeHandler(event);
								}}
								isDisabled={isDisabled}
								{...changeDetector("reparto_seco_horario")}
								defaultValue={horarioSeco}
							>
								{HORARIO_OPCIONES.map((opcion) => (
									<option key={opcion} value={opcion}>
										{opcion}
									</option>
								))}
							</Select>
						</FormControl>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Turno</FormLabel>
							<Menu closeOnSelect={false} isLazy matchWidth strategy="fixed">
								<MenuButton
									w="100%"
									type="button"
									disabled={isDisabled}
									style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
								>
									<Select
										placeholder={getCodigoTurnos(turnosSeco)}
										isReadOnly
										isDisabled={isDisabled}
										value={getCodigoTurnos(turnosSeco)}
										name="reparto_seco_turnos"
										{...changeDetector("reparto_seco_turnos")}
									/>
								</MenuButton>
								<MenuList maxH="15rem" overflow="auto">
									<MenuOptionGroup
										type="checkbox"
										value={turnosSeco}
										onChange={(e) => {
											let value = Array.isArray(e) ? e : [e];
											if (e.includes("all")) {
												if (value.length === TURNOS.length + 1) value = [];
												else value = TURNOS;
											}
											const code = getCodigoTurnos(value);
											if (code) {
												setTurnosSeco(value);
												debouncedMultiChangeHandler("reparto_seco_turnos", code);
											}
										}}
									>
										<MenuItemOption key={"option-" + "all"} value="all">
											{turnosSeco?.length === TURNOS.length ? "Deseleccionar todo" : "Seleccionar todo"}
										</MenuItemOption>
										<MenuDivider />
										{TURNOS.map((it, idx) => (
											<MenuItemOption key={"option-" + idx} value={it}>
												{it}
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Menu>
						</FormControl>
					</GridItem>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Días de reparto: frutas y hortalizas
						</Text>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Dias semana</FormLabel>
							<Menu closeOnSelect={false} isLazy matchWidth strategy="fixed">
								<MenuButton
									w="100%"
									type="button"
									disabled={isDisabled}
									style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
								>
									<Select
										placeholder={getCodigoDiasSemana(diasFyH)}
										isReadOnly
										isDisabled={isDisabled}
										value={getCodigoDiasSemana(diasFyH)}
										name="reparto_fyh_dias"
										{...changeDetector("reparto_fyh_dias")}
									/>
								</MenuButton>
								<MenuList maxH="15rem" overflow="auto">
									<MenuOptionGroup
										type="checkbox"
										value={diasFyH}
										onChange={(e) => {
											let value = Array.isArray(e) ? e : [e];
											if (e.includes("all")) {
												if (value.length === DIAS_SEMANA.length + 1) value = [];
												else value = DIAS_SEMANA;
											}
											const code = getCodigoDiasSemana(value);
											if (code) {
												setDiasFyH(value);
												debouncedMultiChangeHandler("reparto_fyh_dias", code);
											}
										}}
									>
										<MenuItemOption key={"option-" + "all"} value="all">
											{diasFyH?.length === DIAS_SEMANA.length ? "Deseleccionar todo" : "Seleccionar todo"}
										</MenuItemOption>
										<MenuDivider />
										{DIAS_SEMANA.map((it, idx) => (
											<MenuItemOption key={"option-" + idx} value={it}>
												{it}
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Menu>
						</FormControl>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Horario</FormLabel>
							<Select
								value={horarioFyH}
								name="reparto_fyh_horario"
								onChange={(event) => {
									const newValue = event.target.value as HORARIO;
									setHorarioFyH(newValue);
									debouncedChangeHandler(event);
								}}
								isDisabled={isDisabled}
								{...changeDetector("reparto_fyh_horario")}
								defaultValue={horarioFyH}
							>
								{HORARIO_OPCIONES.map((opcion) => (
									<option key={opcion} value={opcion}>
										{opcion}
									</option>
								))}
							</Select>
						</FormControl>
					</GridItem>
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Turno</FormLabel>
							<Menu closeOnSelect={false} isLazy matchWidth strategy="fixed">
								<MenuButton
									w="100%"
									type="button"
									disabled={isDisabled}
									style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
								>
									<Select
										isDisabled={isDisabled}
										placeholder={getCodigoTurnos(turnosFyH)}
										isReadOnly
										value={getCodigoTurnos(turnosFyH)}
										name="reparto_fyh_turnos"
										{...changeDetector("reparto_fyh_turnos")}
									/>
								</MenuButton>
								<MenuList maxH="15rem" overflow="auto">
									<MenuOptionGroup
										type="checkbox"
										value={turnosFyH}
										onChange={(e) => {
											let value = Array.isArray(e) ? e : [e];
											if (e.includes("all")) {
												if (value.length === TURNOS.length + 1) value = [];
												else value = TURNOS;
											}
											const code = getCodigoTurnos(value);
											if (code) {
												setTurnosFyH(value);
												debouncedMultiChangeHandler("reparto_fyh_turnos", code);
											}
										}}
									>
										<MenuItemOption key={"option-" + "all"} value="all">
											{turnosFyH?.length === TURNOS.length ? "Deseleccionar todo" : "Seleccionar todo"}
										</MenuItemOption>
										{TURNOS.map((it, idx) => (
											<MenuItemOption key={"option" + idx} value={it}>
												{it}
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Menu>
						</FormControl>
					</GridItem>
					<GridItem colSpan={4}>
						<FormControl>
							<FormLabel>Dias de reparto (Comentarios)</FormLabel>
							<TextAreaInput
								name="dias_de_reparto"
								defaultValue={seccionDataActual?.dias_de_reparto}
								{...changeDetector("dias_de_reparto")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<VStack alignItems="flex-start" spacing="1.19rem" style={{ pageBreakInside: "avoid" }}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Caracterización
					</Text>
					{checkboxesList.map((checkboxItem, index) => (
						<FormControl key={index}>
							<HStack width="100%">
								<FormLabel flex={1}>{checkboxItem.label}</FormLabel>
								<Box>
									<CheckboxGroup
										name={checkboxItem.name}
										defaultValue={seccionDataActual?.[checkboxItem.name]}
										changed={changeDetector(checkboxItem.name)}
										onChange={(name, value) => sendInputValue(name, value)}
										isDisabled={isDisabled}
									/>
								</Box>
							</HStack>
						</FormControl>
					))}
				</VStack>
				<GridItem mt="1.5rem">
					<FormControl>
						<FormLabel>Comentarios</FormLabel>
						<TextAreaInput
							name="comentarios_2"
							defaultValue={seccionDataActual?.comentarios_2}
							{...changeDetector("comentarios_6")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
			</Box>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_2_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_2_completada", flag ? "1" : "0");
							if (evaluacion?.seccion_1.tipo_de_actividad === "Reparto") {
								sendInputValue("seccion_3_completada", "1");
							}
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion02;
